 <template>
  <v-card>
    <v-card-title>
      Activities
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        append-icon="search"
        label="Search"
        single-line
        hide-details
      ></v-text-field>
      <v-btn @click="$router.push({ name: 'MobileActivityCreate' })" color="primary ml-2">Create Activity</v-btn>
    </v-card-title>

    <v-data-table
      :headers="headers"
      :items="activities"
      :search="search"
    >
      <template v-slot:[`item.name`]="{ item }">
        <span>{{ $languageProcessor.getEnText(item.name) }}</span>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-icon small class="mr-2" @click="$router.push({ path: `/mobile/activity/${item._id}/edit`})">edit</v-icon>
        <v-icon small class="mr-2" @click="deleteActivity">delete</v-icon>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
import RestResource from "@/services/dataServiceMobile.js";

const service = new RestResource();

export default {
  data() {
    return {
      status: [
        { _id: "A", name: "Active" },
        { _id: "I", name: "Inactive" }
      ],
      search: "",
      headers: [
        { text: "Name", value: "name", sortable: true },
        { text: "Type", value: "type", sortable: false },
        { text: "Action", value: "actions", sortable: false }
      ],
      activities: [],
    };
  },

  mounted() {
    this.loadActivities();
  },

  methods: {
    loadActivities() {
      this.$setLoader()
      service.loadActivities().then(r => {
        this.activities = r.data;
        this.$disableLoader()
      });
    },

    deleteActivity(activityId) {
      if (confirm("Are you sure you want to delete this activity?")) {
        service.deleteActivity(activityId).then(res => {
          this.$showSuccessMessage(res.data)
          this.loadActivities();
        })
      } 
    }
  }
};
</script>